<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='editAssetsBuy'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalCenterTitle" class="modal-title">Edit Assets</h5>
            <a class="close" title="Close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Assets Type</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="expense_type"
                                v-model="formData.asset_type_id"
                                id="fixed_assets"
                                :value="assetsElements.fixed_assets_id"
                                @change="getAccountHeads()"
                                required
                              />
                              <label for="fixed_assets" class="ml-2">Fixed Assets</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="expense_type"
                                v-model="formData.asset_type_id"
                                id="current_assets"
                                :value="assetsElements.current_assets_id"
                                @change="getAccountHeads()"
                                required
                              />
                              <label for="current_assets" class="ml-2">Current Assets</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.branch_id"
                              class="form-control"
                              required
                              @change="getPayments"
                            >
                              <option value>Select Branch</option>
                              <option
                                v-for="(branch,index) in assetsElements.filter_branchs"
                                :key="index"
                                :value="branch.id"
                              >{{ branch.name }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Assets ID</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.asset_id"
                              class="form-control"
                              placeholder="id"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              v-model="formData.date"
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Account Head</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              v-model="formData.account_id"
                              required
                            >
                              <option value disabled>Account Head</option>
                              <option
                                v-for="(account,index) in account_heads"
                                :key="index"
                                :value="account.id"
                              >{{account.account_name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Supplier</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select v-model="formData.supplier_id" class="form-control" @change="supplierChange()">
                              <option value>Select Supplier</option>
                              <option
                                v-for="(supplier,index) in assetsElements.suppliers"
                                :key="index"
                                :value="supplier.id"
                              >{{ supplier.name }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0 mt-0">
                            <label class="btn btn-primary ht-20 p-1" for="file">Browse</label>
                          </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              id="file"
                              name="file"
                              style="display: none"
                              type="file"
                              @change="base64Image"
                            />
                            <input
                              class="form-control"
                              placeholder="Upload PDF / Image"
                              readonly
                              style="background: #f4f7fa"
                              v-model="formData.file"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Settings</label>
                      <div class="group-attribute-container">
                        <div class="from-group-check">
                          <input
                            class="group-check-box"
                            type="checkbox"
                            v-model="formData.import"
                            id="expense_import"
                          />
                          <label for="expense_import" class="ml-2">Import</label>
                        </div>
                        <div class="group-note">
                          <span>Note:</span> Please Check the above option if expense is imported
                        </div>
                      </div>
                    </div>
                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              v-model="formData.description"
                              style="height: 3.2rem;"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="from-group-check">
                          <input
                            class="group-check-box"
                            type="checkbox"
                            name="expense_type"
                            id="vat_bill"
                            v-model="formData.vat_bill"
                            value="0"
                            @change="vatBillChange()"
                          />
                          <label for="vat_bill" class="ml-2">VAT Bill</label>
                        </div>
                        <div class="group-note pd-b-7">
                          <span>Note:</span> Please Check the above option for VAT billed expense
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Chalan No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Chalan No."
                              v-model="formData.chalan_no"
                              :required="chalan_required"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Assets Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              v-model="formData.asset_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              required
                               @change="calculateBill()"
                              @keyup="calculateBill()"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">VAT-able Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              v-model="formData.vatable_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              :max="formData.asset_amount"
                              :required="vatable_amount_required"
                              :disabled="vatable_amount_disable"
                              @change="calculateCustomVatableAmount()"
                              @keyup="calculateCustomVatableAmount()"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">VAT Amount</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.vat_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bill Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.bill_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              disabled
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Paid Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              v-model="formData.paid_amount"
                              class="form-control"
                              step="any"
                              placeholder="NRP 0.00"
                              required
                              min="0"
                              :max="formData.bill_amount"
                              :disabled="paid_amount_disabled"
                              @change="calculateDue()"
                              @keyup="calculateDue()"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Remaining Amt.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.due_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Payment Type</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payment Method</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.payment_method_id"
                              class="form-control"
                              required
                              @change="getBanks"
                            >
                              <option value>Payment Method</option>
                              <option
                                v-for="(method,index) in payement_methods"
                                :key="index"
                                :value="method.id"
                              >{{ method.title }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Name</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.bank_id"
                              class="form-control"
                              :disabled="bank_disabled"
                              required
                            >
                              <option value disabled>Select Bank</option>
                              <option
                                v-for="(bank, index) in banks"
                                :key="index"
                                :value="bank.id"
                              >{{ bank.bank }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <!-- Transaction id is replaced with Ref. -->
                          <div class="col-md-5 mr-0 pr-0">Ref.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="input"
                              v-model="formData.transaction_id"
                              placeholder="Payment Ref."
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div v-if="!processing" class="col-md-12 text-right mt-3">
                <button class="btn btn-sm btn-primary" type="submit" :disabled="disableSubmitButton">{{(disableSubmitButton)?'Submitting...':'Submit'}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  components: {},
  data() {
    return {
      account_heads: [],
      formData: {
        id: "",
        asset_type_id: null,
        branch_id: "",
        bill_type:0,  
        asset_id: null,
        document:null,
        date: null,
        account_id: "",
        supplier_id: "",
        payment_method_id: "",
        bank_id: null,
        transaction_id: null,
        chalan_no:null,
        import: 0,
        vat_bill: 0,
        asset_amount: 0,
        vatable_amount: 0,
        vat_amount: 0,
        bill_amount: 0,
        paid_amount: 0,
        due_amount: 0,
        file:null,
        description: null,
      },
      chalan_required: false,
      vatable_amount_disable: true,
      vatable_amount_required: false,
      bank_disabled: true,
      payement_methods: [],
      banks: [],
      paid_amount_disabled: true,
      disableSubmitButton: false,
      branchDisabled:false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists7",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("assets", ["assetsElements", "assetsLists"]),
  },
  mounted() {
    this.formData.date = this.today;
  },
  methods: {
    submit() {
      this.disableSubmitButton = true;
      Services.updateAsset(this.formData.id,this.formData)
        .then((res) => {
          this.$store.commit("assets/setAssetsLists", res.data.data.data);
          this.$store.commit("setDataId", res.data.data.id);
          this.$store.dispatch("modalWithMode", {
            id: "addSlip",
            mode: "read",
          });
          this.$emit("parent-event");
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let error = Object.values(err.response.data.errors).flat();
            this.setNotification(error);
          } else {
            let error = err.response.data.error_message;
            this.setNotification(error);
          }
        })
        .finally(() => {
          this.disableSubmitButton = false;
        });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    base64Image(e) {
      var fileExt = e.target.files[0]["name"].split('.').pop();
      if(fileExt=="jpg"||fileExt=="png"||fileExt=="pdf"||fileExt=="jpeg"){
        this.formData.file = e.target.files[0]["name"];
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.formData.document = e.target.result;
        };
      }else{
        alert("File type not supported.");
      }
    },
    getElements() {
      Services.getAssetsElements()
        .then((res) => {
            this.$store.commit("assets/setAssetsElements", res.data.data);
            let id = this.dataId;
            let details = this.assetsLists.find(function(data){
                return data.id == id;
            });
            if(res.data.data.filter_branchs.length == 1)
          {
            this.branchDisabled = true;
          }
            this.formData = {...details};
            this.formData.file = this.formData.document;
            this.getAccountHeads();
            this.formData.account_id = details.account_id;
            this.getPayments();
            this.formData.payment_method_id = details.payment_method_id;
            this.getBanks();
            this.formData.bank_id = details.bank_id;
            if(details.supplier_id == null){
                this.formData.supplier_id = "";
            }
            this.supplierChange();
            if(this.formData.vat_bill == 1){
              this.vatable_amount_disable = false;
              this.vatable_amount_required=true;
              this.chalan_required = true;
            }
            })
        .catch((err) => {
          console.log(err);
        });
    },
    getAccountHeads() {
      this.formData.account_id = "";
      if (this.assetsElements.accounts.length != 0) {
        let asset_type = this.formData.asset_type_id;
        let details = this.assetsElements.accounts.filter(function (data) {
          return data.account_type == asset_type;
        });
        this.account_heads = { ...details };
      }
    },
    supplierChange() {
      if (this.formData.supplier_id != "") {
        this.paid_amount_disabled = false;
      } else {
        this.formData.paid_amount = this.formData.bill_amount;
        this.paid_amount_disabled = true;
      }
    },
    getPayments() {
      this.bank_disabled = true;
      this.formData.bank_id = "";
      if (this.assetsElements.payment_methods.length != 0) {
        let details = [];
        let methods = this.formData.branch_id;
        details = this.assetsElements.payment_methods.filter(function (data) {
          return data.branch == methods;
        });
        this.payement_methods = { ...details };
        this.formData.payment_method_id = this.payement_methods[0].id;
      }
    },
    getBanks() {
      let payment_bank = this.formData.payment_method_id;
      let values = this.assetsElements.payment_methods.find(function (data) {
        if (data.id == payment_bank) {
          return data.title;
        }
      });
      if (values.title.toLowerCase() == "bank") {
        if (this.assetsElements.banks.length != 0) {
          this.bank_disabled = false;
          let details = [];
          let methods = this.formData.branch_id;
          details = this.assetsElements.banks.filter(function (data) {
            return data.branch == methods;
          });
          this.banks = { ...details };
          if (details.length > 0) {
            this.formData.bank_id = details[0].id;
          } else {
            this.formData.bank_id = "";
          }
        } else {
          this.bank_disabled = false;
          this.formData.bank_id = "";
        }
      } else {
        this.bank_disabled = true;
        this.formData.bank_id = "";
      }
    },
     vatBillChange() {
      if (this.formData.vat_bill == 0) {
        this.chalan_required = false;
        this.vatable_amount_disable = true;
        this.vatable_amount_required = false;
        this.formData.vatable_amount = 0;
      } else {
        this.chalan_required = true;
        this.vatable_amount_disable = false;
        this.vatable_amount_required = true;
      }
      this.calculateBill();
    },
    calculateBill() {
      if (this.formData.vat_bill == 0) {
        this.formData.vatable_amount = 0;
        this.formData.vat_amount = 0;
        this.formData.bill_amount = this.parseDigit(this.formData.asset_amount);
        this.formData.paid_amount = this.parseDigit(this.formData.asset_amount);
        this.formData.due_amount =
         this.parseDigit( parseFloat(this.formData.bill_amount) -
          parseFloat(this.formData.paid_amount));
      }
      if (this.formData.vat_bill == 1) {
        this.formData.vatable_amount = this.parseDigit(this.formData.asset_amount);
        this.formData.vat_amount =
          this.parseDigit(this.formData.vatable_amount * 0.13);
        this.formData.bill_amount =
         this.parseDigit( parseFloat(this.formData.asset_amount) +
          parseFloat(this.formData.vat_amount));
        this.formData.paid_amount = this.parseDigit(this.formData.bill_amount);
        this.formData.due_amount =
          this.parseDigit(parseFloat(this.formData.bill_amount) -
          parseFloat(this.formData.paid_amount));
      }
    },
    calculateCustomVatableAmount() {
      //  this.formData.vatable_amount = parseFloat(this.formData.expense_amount);
      this.formData.vat_amount =
        this.parseDigit(this.formData.vatable_amount * 0.13);
      this.formData.bill_amount =
        this.parseDigit(parseFloat(this.formData.asset_amount) +
        parseFloat(this.formData.vat_amount));
      this.formData.paid_amount = this.parseDigit(this.formData.bill_amount);
      this.formData.due_amount =
        this.parseDigit(parseFloat(this.formData.bill_amount) -
        parseFloat(this.formData.paid_amount));
    },
    calculateDue() {
      this.formData.due_amount =
        this.parseDigit(parseFloat(this.formData.bill_amount) -
        parseFloat(this.formData.paid_amount));
    },
    clearData(){
      this.account_heads = [];
      this.formData.id = "";
      this.formData.type_id = "";
      this.formData.branch_id = "";
      this.formData.asset_type_id = null;
      this.formData.asset_id=null;
      this.formData.date = this.today;
      this.formData.account_id = "";
      this.formData.payment_method_id = "";
      this.formData.bank_id = "";
      this.formData.transaction_id = null;
      this.formData.supplier_id = "";
      this.formData.import = 0;
      this.formData.vat_bill = 0;
      this.formData.asset_amount = 0;
      this.formData.chalan_no="";
      this.formData.vatable_amount = 0;
      this.formData.vat_amount = 0;
      this.formData.bill_amount = 0;
      this.formData.paid_amount = 0;
      this.formData.due_amount = 0;
      this.formData.file=null,
      this.formData.description = null;
      this.chalan_required = false;
      this.vatable_amount_disable = true;
      this.vatable_amount_required = false;
      this.bank_disabled = true;
      this.payement_methods = [];
      this.banks = [];
      this.paid_amount_disabled = true;
      this.disableSubmitButton = false;
    },
  },
  watch: {
    modalId(value) {
      if (value == "editAssetsBuy") {
        this.clearData();
        this.getElements();
      }
    },
  },
};
</script>
