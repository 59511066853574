<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-4">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Account</a>
                </li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">Assets</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Assets</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-8 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('new_asset')"
          class="btn btn-primary mg-t-8 mr-2 mg-b-0"
          @click="$store.dispatch('modalWithMode', { id: 'newAssets', mode: 'create' })"
        >New Assets</button>
        <button
          type="button"
          v-if="checkSinglePermission('sell_asset')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="$store.dispatch('modalWithMode', { id: 'sellAssets', mode: 'sell' })"
        >Sell Assets</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-10 d-block mr-0 pr-0">
          <select v-model="params.pageIndex" id="asset_filter_size" @change="searchFilter" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select v-model="params.branch" class="filter-input mr-2 mg-t-5" id="asset_filter_branch_type" @change="searchFilter">
            <option value>All Branch</option>
            <option
              v-for="(branch,index) in assetsElements.filter_branchs"
              :key="index"
              :value="branch.id"
            >{{branch.name}}</option>
          </select>
          <select v-model="params.type" class="filter-input mr-2 mg-t-5" id="asset_filter_asset_type" @change="searchFilter">
            <option value>All Assets Type</option>
            <option value="8">Fixed Assets</option>
            <option value="7">Current Assets</option>
          </select>
          <SearchDayMonthComponent @clicked="monthDayClicked"></SearchDayMonthComponent>
          <div class="mr-2 d-inline-block">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input type="date" v-model="params.from_date" placeholder="From" class="filter-input mr-1 mg-t-5" id="asset_filter_date_from"  @change="searchFilter"/>
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input type="date" v-model="params.to_date" placeholder="To" class="filter-input mr-1 mg-t-5" id="asset_filter_date_to" @change="searchFilter" />
          </div>
        </div>
        <div class="col-md-2 ml-0 pl-0 mg-t-5">
          <input type="text" v-model="params.searched" @keyup="searchKeyword" class="float-right" id="asset_filter_search" placeholder="Search..." />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Asset Id</th>
              <th class="wd-15p">Account Type</th>
              <th class="wd-15p">Account Name</th>
              <th class="wd-10p">Payment Method</th>
              <th class="wd-10p">Amount</th>
              <th class="wd-10p">Status</th>
              <th class="wd-15p text-center table-end-item" v-if="checkIfAnyPermission(['view_asset','edit_asset','edit_sell_asset','cancel_asset'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && assetsLists.length > 0">
            <tr v-for="(asset, index) in assetsLists" :key="index">
              <th scope="row" class="table-start-account">{{ pageSerialNo+index }}.</th>
              <td>{{asset.date}}</td>
              <td>#{{asset.asset_id}} <span v-if="asset.status==1" class="tx-danger">-Canceled</span></td>
              <td>{{asset.account_type.title}}</td>
              <td>
                {{asset.account.account_name}}
              </td>
              <td>{{asset.payment_method.title}}
                <span v-if="asset.bank_id">-{{asset.bank.bank}}</span>
              </td>
              <td><span v-if="asset.bill_type == 0">{{parseDigitForSlip(asset.bill_amount)}}</span><span v-else>{{parseDigitForSlip(asset.selling_value)}}</span></td>
              <td ><span class="tx-success">{{(asset.bill_type==0)?'Purchased':'Sales'}}</span></td>
              <td class="text-center table-end-item" v-if="checkIfAnyPermission(['view_asset','edit_asset','edit_sell_asset','cancel_asset'])">
                <a href="javascript:;" class="mr-3" :id="'assetView'+asset.id">
                  <i
                    class="fas fa-eye tx-success"
                    title="View"
                    v-if="checkSinglePermission('view_asset')"
                    @click="$store.commit('setDataId', asset.id),$store.dispatch('modalWithMode', { id: 'addSlip', mode: 'view' })"
                  ></i>
                </a>
                <a href="javascript:;" v-if="asset.status == 0 && checkIfAnyPermission(['edit_asset','edit_sell_asset'])" class="mr-3" :id="'assetEdit'+asset.id" @click="edit((asset.bill_type==0)?'editAssetsBuy':'editSellAssets', asset.id)">
                  <i class="fa fa-edit" title="Edit"></i>
                </a>
                <a href="javascript:;" v-if="asset.status == 0 && checkSinglePermission('cancel_asset')" :id="'assetCancel'+asset.id" @click="cancel(asset.id)">
                  <i class="fas fa-times-circle tx-danger" title="Cancel"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && assetsLists.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <td colspan="11" class="text-center">
             {{error_message}}
            </td>
          </tbody>
        </table>
        <Paginate 
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
        <Create @parent-event="getData()"/>
        <EditBuyAsset @parent-event="getData()"/>
        <SellAssets @parent-event="getData()"/>
        <EditAssetSale @parent-event="getData()"/>
        <AddSlip />
        <SellSlip />
      </div>
    </div>
  </div>
</template>
<script>
import Create from "./create";
import EditBuyAsset from "./edit";
import SellAssets from "./sellAssets";
import AddSlip from "./addSlip";
import Paginate from "vuejs-paginate";
import SellSlip from "./sellSlip";
import EditAssetSale from "./editSellAssets";
import { mapGetters } from "vuex";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import Services from "./Services/Services";
import _ from "lodash";
export default {
  components: {
    Create,
    EditBuyAsset,
    SellAssets,
    AddSlip,
    SellSlip,
    SearchDayMonthComponent,
    EditAssetSale,
    Paginate,
  },
  data() {
    return {
      params: {
        pageIndex: 10,
        searched: "",
        offset: 0,
        branch: "",
        type: "",
        from_date: "",
        to_date: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      totalPageCount: 0,
      pageSerialNo: 1,
      loading: true,
      error: false,
      page: 1,
    };
  },
  methods: {
    clickCallback: function(page) {
      if(page == 1){

        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.pageIndex) + 1 ;
      }
      this.params.offset = (page-1) * this.params.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    monthDayClicked(value) {
      if (value) {
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        today: this.params.day,
        month: this.params.month + 1,
        type:this.params.type,
        year: this.params.year,
        from_date: this.params.from_date,
        to_date: this.params.to_date,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        type:this.params.type,
        branch: this.params.branch,
        today: "",
        month: "",
        year: this.params.year,
        from_date: this.params.from_date,
        to_date: this.params.to_date,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.loading = true;
      Services.getAssetsList(params)
        .then((res) => {
          this.$store.commit("assets/setAssetsLists", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      //this.$store.commit("getData", `api/expenses/size/${this.filter.size}`);
      this.getFilteredDataWithoutDates();
      this.getElements();
    },
    searchFilter(){
      this.params.offset = 0;
      this.pageSerialNo=1;
      this.page=1;
      this.getFilteredDataWithoutDates();
    },
    searchKeyword:_.debounce(function(){
      this.params.offset = 0; 
      this.pageSerialNo=1;
      this.page=1;
      this.getFilteredDataWithoutDates();
    },1000),
    getElements() {
      Services.getAssetsElements()
        .then((res) => {
          this.$store.commit("assets/setAssetsElements", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    cancel(id) {
      this.$swal({
        title: "Do you really want to cancel this Asset ?",
        html: `<label>Remarks: </label>
                <textarea id="cancel-remarks" class="swal2-input"></textarea>
            `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let remarks = document.getElementById("cancel-remarks").value;
          // cancel the payin with remarks
         return Services.cancelAsset(id, { remarks: remarks })
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
                this.getData();
              }
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists8",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("assets",["assetsLists","assetsElements"])
  },
  mounted() {
    this.getData();
  },
};
</script>