<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'sellSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content border0">
          <div class="modal-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Assets Sold Slip - #SEL{{threeDigit(details.id)}}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  @click="$store.dispatch('modalClose')"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center">
              <p class="font-xxl mb-0">Softechpark Pvt. Ltd.</p>
              <p class="mb-0">
                201 Something St., Something Town, YT 242, Country
                <br />Tel No: 324 445-4544 / youremail@companyname.com
                <br />PAN: 123456789
              </p>
              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0">
                <label class="content-label mb-1">Fixed Assets</label>
                <p class="mg-b-0">ACCOUNT ID : #AST{{threeDigit(details.account.id)}}</p>
                <p class="mg-b-0">
                  ACCOUNT NAME : {{details.account.account_name}}
                </p>
              </div>
              <!-- col -->
              <div class="col-sm-6 col-lg-4 mg-t-15">
                <p class="mg-b-0 text-right">
                  TXN. ID : #SEL{{threeDigit(details.id)}}
                </p>
                <p class="mg-b-0 text-right">DATE : {{details.asset_date}}</p>
              </div>
              <!-- col -->
            </div>
            <hr />
            <!-- row -->
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="col-sm-7 col-lg-7 order-2 order-sm-0 mg-t-40 mg-sm-t-0"
              >
                <!-- Amount-in-word -->
                <div class="footer_detail mt-0 p-2">
                  <label class="content-label mb-1">Amount In Word</label>
                  <p class="mg-b-0">
                    {{toWord(details.selling_amount)}}
                  </p>
                </div>
              </div>
              <!-- col -->
              <div
                class="col-sm-4 col-lg-4 order-1 order-sm-0 color-secondary mb-0 mt-0"
              >
                <ul class="list-unstyled lh-6 mb-0">
                  <li class="d-flex justify-content-between">
                    <span>Assets Value</span>
                    <span>{{details.asset_value}}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Sell Amount</span>
                    <span>{{details.selling_amount}}</span>
                  </li>
                  <li class="d-flex justify-content-between" v-if="details.asset_value>details.selling_amount">
                    <span>Total Loss</span>
                    <span>{{details.asset_value - details.selling_amount}}</span>
                  </li>
                </ul>
              </div>
              <!-- col -->
            </div>

            <hr />
            <div class="footer_note" v-if="details.selling_description">
              <label class="content-label mb-1">Note</label>
              <p class="mb-2">
                {{details.selling_description}}
              </p>
            </div>
            <p class="text-right">User: {{details.user_details.name}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  export default {
    data(){
      return{
        details:[],
      }
    },
    computed: {
      ...mapGetters([
        "processing",
        "eventMessage",
        "dataLists",
        "modalId",
        "modalState",
        "dataId"
      ]),
    },watch: {
      dataId(value) {
        this.details = this.dataLists.find(function (data) {
          return data.id == value;
        });
      },
    },
  };
</script>